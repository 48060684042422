import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import style from './style';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { CFormSelect, CTable } from '@coreui/react';
import Allert from '@maket/js/components/mainComponents/sidebar/allert';
import useTheme from '@maket/js/hooks/useTheme';
import { TactiveLanguage } from '@maket/js/types/state/language';
import { Taccount, Tchat, Tuser } from '@maket/js/types/state/auth';
import chats from '@maket/js/api/requiresToken/chat/chats';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import Switch from '@maket/js/components/microComponets/switch';
import setActiveChat from '@maket/js/api/requiresToken/chat/setActiveChat';
import { TappDispatch } from '@maket/js/state/store';
import addProfileChat from '@maket/js/api/requiresToken/chat/addProfileChat';
import deleteProfileChat from '@maket/js/api/requiresToken/chat/deleteProfileChat';
import MobileTable from './mobileTable';
import { Link, useParams } from 'react-router-dom';
import { tgUserLinkBase } from '@maket/js/constants/values';
import { setActiveProfileChats } from '@maket/js/state/auth/authState';

let lastActiveProfileId: null | number = 0;

export default () => {
    const {
        Container,
        ChatListMain,
        OneCell,
        OneCellMain,
        OneCellPopap,
        TwoCell,
        TwoCellIcon,
        ThreeCell,
        FourCell,
        FiveCell,
        FiveCellIcon,
        size,
    } = style();
    const { whiteText } = useTheme();

    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const profiles = useAppSelector(state => state.authState.user);
    const activeProfileChats = useAppSelector(state => state.authState.activeProfileChats);
    const account = useAppSelector(state => state.authState.account);
    const authState = useAppSelector(state => state.authState);

    const { idProfile } = useParams();

    const [ActiveProfileId, setActiveProfileId] = useState<number>(lastActiveProfileId);

    useLayoutEffect(() => {
        if (idProfile && activeProfileChats.length > 0 && !lastActiveProfileId) {
            dispatch(setActiveProfileChats([]));
        }
    }, []);

    useEffect(() => {
        if (lastActiveProfileId == null || lastActiveProfileId != ActiveProfileId) {
            lastActiveProfileId = ActiveProfileId;
            if (token) {
                if (
                    idProfile ? (ActiveProfileId == 0 ? false : ActiveProfileId) : ActiveProfileId
                ) {
                    chats({
                        token,
                        dispatch,
                        profile_id: ActiveProfileId,
                        setActiveProfileId,
                        idProfile,
                    });
                }
            }
        }
    }, [ActiveProfileId, token]);

    useEffect(() => {
        if (!activeProfileChats[0]) {
            if (idProfile == undefined) {
                setActiveProfileId(0);
            }
        }
    }, [activeProfileChats]);

    useEffect(() => {
        if (idProfile) {
            setActiveProfileId(+idProfile);
        } else {
            setActiveProfileId(0);
        }
    }, [idProfile]);

    const columns = createColumns(
        activeLanguage,
        profiles,
        ActiveProfileId,
        setActiveProfileId,
        idProfile,
        token,
        dispatch,
    );
    const rows = createRows(
        dispatch,
        token,
        activeProfileChats,
        activeLanguage,
        account,
        profiles,
        ActiveProfileId,
        setActiveProfileId,
        OneCell,
        OneCellMain,
        OneCellPopap,
        TwoCell,
        TwoCellIcon,
        ThreeCell,
        FourCell,
        FiveCell,
        FiveCellIcon,
    );

    const TableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        if (TableRef.current) {
            const trS = TableRef.current.querySelectorAll('tr');
            trS.forEach(el => {
                const scrHTrs = el.scrollHeight;
                el.querySelectorAll('td').forEach(elD => {
                    elD.setAttribute('style', `height: ${scrHTrs}px`);
                });
            });
        }
    });

    const checkAddChat =
        authState.activeProfileChats &&
        authState.activeProfileChats[0] &&
        authState.activeProfileChats.reduce((acc: Array<Tchat>, item) => {
            if (item.profile_id == null && item.owner_id == null && item.bot_status == 'connected') {
                acc.push(item);
            }
            return acc;
        }, [])[0];

    if (activeProfileChats[0]) {
        if (size.isMobile || size.isTab) {
            return (
                <MobileTable
                    ActiveProfileId={ActiveProfileId}
                    setActiveProfileId={setActiveProfileId}
                />
            );
        }
        return (
            <ChatListMain>
                {checkAddChat && <Allert style={{ color: whiteText }} type="addChat" line></Allert>}
                {/* @ts-ignore: Unreachable code error*/}
                <CTable
                    ref={TableRef}
                    columns={columns}
                    items={rows}
                    striped
                    style={{ fontSize: '0.875em' }}
                />
            </ChatListMain>
        );
    } else {
        return <Container>{activeLanguage['ChatList.ListPlugText']}</Container>;
    }
};

function createColumns(
    activeLanguage: TactiveLanguage,
    profiles: Tuser,
    ActiveProfileId: number,
    setActiveProfileId: React.Dispatch<React.SetStateAction<number>>,
    idProfile: string | undefined,
    token: string,
    dispatch: TappDispatch,
) {
    return [
        {
            key: 'col1',
            label: '',
            _props: {
                scope: 'col',
                color: 'primary',
                style: { width: '15%' },
            },
        },
        {
            key: 'col2',
            label: activeLanguage['ChatList.Table.Col2'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '20%' },
            },
        },
        {
            key: 'col3',
            label: activeLanguage['ChatList.Table.Col3'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '20%' },
            },
        },
        {
            key: 'col4',
            label: activeLanguage['ChatList.Table.Col4'],
            _props: {
                scope: 'col',
                color: 'primary',
                align: 'center',
                valign: 'middle',
                style: { width: '20%' },
            },
        },
        {
            key: 'col5',
            label: (
                <CFormSelect
                    defaultValue={idProfile != undefined ? idProfile : ActiveProfileId}
                    onChange={e => {
                        if (+e.target.value == 0) {
                            chats({
                                token,
                                dispatch,
                                profile_id: 0,
                                setActiveProfileId,
                                idProfile,
                            });
                        }
                        setActiveProfileId(+e.target.value);
                    }}
                    disabled={idProfile ? true : false}
                    options={
                        profiles
                            ? [
                                  { label: activeLanguage['All.'], value: '0' },
                                  ...profiles.profiles
                                      .map(el => {
                                          el = { ...el };
                                          if (el.name == '' || el.name == null) {
                                              el.name = activeLanguage['ProfileList.Name.Init'];
                                          }
                                          return el;
                                      })
                                      .map(el => ({
                                          label: `${el.name}`,
                                          value: `${el.id}`,
                                      })),
                              ]
                            : []
                    }
                />
            ),
            _props: { scope: 'col', color: 'primary', style: { width: '20%' } },
        },
    ];
}

export const configState = {
    not_in_chat: { langText: 'ChatList.List.State.NotInChat', type: 'delete' },
    connected: { langText: 'ChatList.List.State.Connected', type: 'connect' },
    insufficient_rights: { langText: 'ChatList.List.State.InsufficientRights', type: 'notAdmin' },
};

function createRows(
    dispatch: TappDispatch,
    token: string,
    activeProfileChats: Tchat[],
    activeLanguage: TactiveLanguage,
    account: Taccount,
    profiles: Tuser,
    ActiveProfileId: number,
    setActiveProfileId: React.Dispatch<React.SetStateAction<number>>,
    OneCell: any,
    OneCellMain: any,
    OneCellPopap: any,
    TwoCell: any,
    TwoCellIcon: any,
    ThreeCell: any,
    FourCell: any,
    FiveCell: any,
    FiveCellIcon: any,
) {
    const rows: Array<{}> = [];
    if (account) {
        [...activeProfileChats]
            .sort((p, n) => {
                const check =
                    n.bot_status == 'insufficient_rights' || n.bot_status == 'not_in_chat'
                        ? true
                        : n.owner_name != account.username;
                if (check) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .map(el => {
                rows.push({
                    col1: (
                        <OneCell>
                            <OneCellMain data-switchchatbox={`${el.chat_id}`}>
                                <Switch
                                    initState={el.active}
                                    setState={p => {
                                        setActiveChat({
                                            dispatch,
                                            token,
                                            chat_id: +el.chat_id,
                                            active: p,
                                        }).then(res =>
                                            chats({
                                                dispatch,
                                                token,
                                                profile_id: ActiveProfileId || 0,
                                            }),
                                        );
                                    }}
                                    closed={
                                        el.bot_status == 'insufficient_rights' ||
                                        el.bot_status == 'not_in_chat'
                                            ? true
                                            : el.owner_name != account.username
                                    }
                                />
                                {
                                    activeLanguage[
                                        el.owner_name == account.username
                                            ? 'ChatList.List.State.Active'
                                            : 'ChatList.List.State.NotActive'
                                    ]
                                }
                            </OneCellMain>
                            {(el.owner_name != account.username ||
                                el.bot_status == 'insufficient_rights') && (
                                <>
                                    <TwoCellIcon className={'info'} src="/img/icon/info.svg" />
                                    <OneCellPopap className={'infoPopap'}>
                                        {el.bot_status == 'insufficient_rights' ||
                                        el.bot_status == 'not_in_chat'
                                            ? activeLanguage['ChatList.PopapLockMine']
                                            : el.owner_id && !el.is_current_user_owner
                                            ? activeLanguage['ChatList.PopapLock']
                                            : !el.owner_id && el.bot_status == 'connected'
                                            ? activeLanguage['ChatList.PopapLockFree']
                                            : ''}
                                    </OneCellPopap>
                                </>
                            )}
                        </OneCell>
                    ),
                    col2: (
                        <TwoCell>
                            {/* <TwoCellIcon src="/img/icon/snowUI_logo.svg" /> */}
                            {el.chat_name}
                        </TwoCell>
                    ),
                    col3: (
                        <ThreeCell className={`${configState[el.bot_status].type}`}>
                            {activeLanguage[configState[el.bot_status].langText]}
                        </ThreeCell>
                    ),
                    col4: (
                        <FourCell className={`${el.owner_name == account.username ? '' : 'blue'}`}>
                            {el.owner_name == account.username ? (
                                activeLanguage['ChatList.List.Admin.Mine']
                            ) : el.owner_name ? (
                                <Link to={`${tgUserLinkBase}${el.owner_name}`}>
                                    @{el.owner_name}
                                </Link>
                            ) : (
                                <></>
                            )}
                        </FourCell>
                    ),
                    col5: (
                        <FiveCell>
                            <CFormSelect
                                value={el.profile_id || 'init'}
                                onChange={e => {
                                    const switchChatBox = document.querySelector(
                                        `[data-switchchatbox="${el.chat_id}"]`,
                                    );
                                    const isActive = switchChatBox?.querySelector('input')?.checked;

                                    if (e.target.value == 'delete') {
                                        deleteProfileChat({
                                            dispatch,
                                            token,
                                            profile_id: +el.profile_id,
                                            chat_id: +el.chat_id,
                                        }).then(res => {
                                            chats({
                                                dispatch,
                                                token,
                                                profile_id: ActiveProfileId || 0,
                                            });
                                        });
                                    } else {
                                        if (el.profile_name) {
                                            deleteProfileChat({
                                                dispatch,
                                                token,
                                                profile_id: +el.profile_id,
                                                chat_id: +el.chat_id,
                                                new_profile_id: +e.target.value,
                                            }).then(res => {
                                                if (res) {
                                                    addProfileChat({
                                                        dispatch,
                                                        token,
                                                        profile_id: res,
                                                        chat_id: +el.chat_id,
                                                        isActive: isActive || false,
                                                    }).then(res => {
                                                        chats({
                                                            dispatch,
                                                            token,
                                                            profile_id: ActiveProfileId || 0,
                                                        });
                                                    });
                                                }
                                            });
                                        } else {
                                            addProfileChat({
                                                dispatch,
                                                token,
                                                profile_id: +e.target.value,
                                                chat_id: +el.chat_id,
                                                isActive: isActive || false,
                                            }).then(res => {
                                                chats({
                                                    dispatch,
                                                    token,
                                                    profile_id: ActiveProfileId || 0,
                                                });
                                            });
                                        }
                                    }
                                }}
                                options={
                                    profiles
                                        ? [
                                              {
                                                  label: activeLanguage[''],
                                                  value: 'init',
                                                  disabled: true,
                                              },
                                              ...profiles.profiles.map(pr => ({
                                                  label: `${
                                                      pr.name != '' || pr.name == null
                                                          ? pr.name
                                                          : activeLanguage['ProfileList.Name.Init']
                                                  }`,
                                                  value: `${pr.id}`,
                                              })),
                                              {
                                                  label: activeLanguage['ChatList.WithoutProfile'],
                                                  value: 'delete',
                                              },
                                          ]
                                        : [el.chat_id]
                                }
                                disabled={
                                    el.bot_status != 'connected'
                                        ? true
                                        : el.owner_id == null
                                        ? false
                                        : !el.is_current_user_owner
                                }
                            />
                            <FiveCellIcon
                                to={'/app/profileSettings/' + el.profile_id}
                                onClick={e => {
                                    if (el.profile_id == null) {
                                        e.preventDefault();
                                    }
                                }}
                                className={`icon-arrowCircle ${
                                    el.profile_id == null ? 'gray' : ''
                                }`}
                            />
                        </FiveCell>
                    ),
                    _cellProps: {
                        class: { scope: 'row' },
                        align: 'center',
                        valign: 'middle',
                    },
                });
            });
    }

    return rows;
}
