import React, { useEffect, useState } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import postBan from '@maket/js/api/requiresToken/rule/postBan';
import postMute from '@maket/js/api/requiresToken/rule/postMute';
import postExeption from '@maket/js/api/requiresToken/rule/postExeption';
import { useParams } from 'react-router-dom';
import getBan from '@maket/js/api/requiresToken/rule/getBan';
import getUnBan from '@maket/js/api/requiresToken/rule/getUnBan';
import getMute from '@maket/js/api/requiresToken/rule/getMute';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';
import { setBlockedPopap } from '@maket/js/state/savedForms';

import { useBeforeUnload } from 'react-router-dom';
import { useBlocker, useNavigate } from 'react-router';
import {
    clearChange,
    setMuteEndingFraceState,
    setMuteStartingFraceState,
} from '@maket/js/state/banAndMuteRules';

export default () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {} = style();
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const { banState, exeptionState, muteState, BDbanState, BDexeptionState, BDmuteState } =
        useAppSelector(state => state.banAndMuteRulesState);
    const token = useAppSelector(state => state.tokenState.token);

    const user = useAppSelector(state => state.authState.user);
    const rulesProfile = useAppSelector(state => state.banAndMuteRulesState.rulesProfile);

    const [allertState, setallertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    let checkBan = false;
    let checkMute = false;
    let checkExeption = false;
    if (user) {
        checkBan = user.profiles[0].settings.ban_rules;
        checkMute = user.profiles[0].settings.mute_rules;
        checkExeption = user.profiles[0].settings.unban_rules;
    }

    const { idProfile } = useParams();
    const [notClicked, setNotClicked] = useState(true);

    useEffect(() => {
        if (idProfile) {
            let check = false;

            if (banState.spam_filter != BDbanState.spam_filter) {
                check = true;
            }
            if (banState.rules.length != BDbanState.rules.length) {
                check = true;
            }
            if (exeptionState.rules.length != BDexeptionState.rules.length) {
                check = true;
            }
            if (muteState.rules.length != BDmuteState.rules.length) {
                check = true;
            }

            if ((muteState.ending_frase ?? '') != (BDmuteState.ending_frase ?? '')) {
                check = true;
            }
            if ((muteState.starting_frase ?? '') != (BDmuteState.starting_frase ?? '')) {
                check = true;
            }

            banState.rules.forEach(el => {
                const checkItem = BDbanState.rules.find(
                    elItem => elItem.text == el.text && elItem.enable == el.enable,
                );
                if (checkItem == undefined) {
                    check = true;
                }
            });

            exeptionState.rules.forEach(el => {
                const checkItem = BDexeptionState.rules.find(
                    elItem => elItem.text == el.text && elItem.enable == el.enable,
                );
                if (checkItem == undefined) {
                    check = true;
                }
            });

            muteState.rules.forEach(el => {
                const checkItem = BDmuteState.rules.find(
                    elItem =>
                        elItem.reason == el.reason &&
                        elItem.response == el.response &&
                        elItem.enable == el.enable,
                );
                if (checkItem == undefined) {
                    check = true;
                }
            });

            if (check) {
                setNotClicked(false);
            } else {
                setNotClicked(true);
            }
        }
    }, [banState, exeptionState, muteState, BDbanState, BDexeptionState, BDmuteState]);

    useBlocker(n => {
        if (!notClicked) {
            dispatch(
                setBlockedPopap({
                    state: true,
                    resolveFn: () => {
                        buttonClickFn();

                        dispatch(clearChange());
                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);
                        }, 300);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );
                    },
                    rejectFn: () => {
                        dispatch(clearChange());

                        setTimeout(() => {
                            navigate(n.nextLocation.pathname);
                        }, 300);

                        dispatch(
                            setBlockedPopap({
                                state: false,
                                rejectFn: () => {},
                                resolveFn: () => {},
                            }),
                        );

                        return false;
                    },
                }),
            );
            return true;
        } else {
            return false;
        }
    });

    function buttonClickFn() {
        if (checkBan) {
            postBan({ token, data: banState, rulesProfile })
                .then(res => {
                    getBan({ token, dispatch, rulesProfile });
                    if (allertState.textAllert != 'MyData.Update.Success') {
                        setallertState({
                            textAllert: 'MyData.Update.Success',
                            typeAllert: 'success',
                        });
                    }
                })
                .catch(err => {
                    if (err.response?.data.detail[0].msg.includes('Not supported by tariff')) {
                        if (allertState.textAllert != 'ProfileSettings.Alert.NotSend') {
                            setallertState({
                                textAllert: 'ProfileSettings.Alert.NotSend',
                                typeAllert: 'danger',
                            });
                        }
                    }
                });
        }
        if (checkExeption) {
            postExeption({ token, data: exeptionState, rulesProfile })
                .then(res => {
                    getUnBan({ token, dispatch, rulesProfile });
                    if (allertState.textAllert != 'MyData.Update.Success') {
                        setallertState({
                            textAllert: 'MyData.Update.Success',
                            typeAllert: 'success',
                        });
                    }
                })
                .catch(err => {
                    if (err.response?.data.detail[0].msg.includes('Not supported by tariff')) {
                        if (allertState.textAllert != 'ProfileSettings.Alert.NotSend') {
                            setallertState({
                                textAllert: 'ProfileSettings.Alert.NotSend',
                                typeAllert: 'danger',
                            });
                        }
                    }
                });
        }
        if (checkMute) {
            let checkOnliWhitespaceEnd = true;
            [...muteState.ending_frase].forEach(el => {
                if (el != ' ') {
                    checkOnliWhitespaceEnd = false;
                }
            });
            let checkOnliWhitespaceStart = true;
            [...muteState.starting_frase].forEach(el => {
                if (el != ' ') {
                    checkOnliWhitespaceStart = false;
                }
            });
            postMute({ token, data: muteState, rulesProfile })
                .then(res => {
                    getMute({ token, dispatch, activeLanguage, rulesProfile });
                    if (allertState.textAllert != 'MyData.Update.Success') {
                        setallertState({
                            textAllert: 'MyData.Update.Success',
                            typeAllert: 'success',
                        });
                    }
                })
                .catch(err => {
                    if (err.response?.data.detail[0].msg.includes('Not supported by tariff')) {
                        if (allertState.textAllert != 'ProfileSettings.Alert.NotSend') {
                            setallertState({
                                textAllert: 'ProfileSettings.Alert.NotSend',
                                typeAllert: 'danger',
                            });
                        }
                    }
                });
        }
    }

    return (
        <div
            style={{
                width: '100%',
                opacity: checkBan || checkMute ? '1' : '0.7',
                pointerEvents: checkBan || checkMute ? 'all' : 'none',
            }}
        >
            <ButtonAllert
                onClick={() => {
                    const loader = document.getElementById('loader');
                    const check = loader?.className.includes('opacityNone');
                    if (check) {
                        buttonClickFn();
                    }
                }}
                setAllertFn={setallertState}
                stateAllertOuter={allertState}
                disabled={notClicked}
                typeButt="black"
                buttonContent={activeLanguage['Save.']}
            ></ButtonAllert>
        </div>
    );
};
