import React, { useEffect } from 'react';
import style from './style';
import { CFormSelect } from '@coreui/react';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useTheme from '@maket/js/hooks/useTheme';
import chats from '@maket/js/api/requiresToken/chat/chats';
import Switch from '@maket/js/components/microComponets/switch';
import setActiveChat from '@maket/js/api/requiresToken/chat/setActiveChat';
import { configState } from '.';
import deleteProfileChat from '@maket/js/api/requiresToken/chat/deleteProfileChat';
import addProfileChat from '@maket/js/api/requiresToken/chat/addProfileChat';
import { Tchat } from '@maket/js/types/state/auth';
import Allert from '@maket/js/components/mainComponents/sidebar/allert';
import { Link } from 'react-router-dom';
import { tgUserLinkBase } from '@maket/js/constants/values';

type Tprops = {
    ActiveProfileId: number;
    setActiveProfileId: React.Dispatch<React.SetStateAction<number>>;
};

export default ({ ActiveProfileId, setActiveProfileId }: Tprops) => {
    const { MobileTable, MobileTableItem, MobileTableItemTitle } = style();
    const {
        OneCell,
        OneCellMain,
        OneCellPopap,
        TwoCell,
        TwoCellIcon,
        ThreeCell,
        FourCell,
        FiveCell,
        FiveCellIcon,
        size,
    } = style();

    const { whiteText } = useTheme();

    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const profiles = useAppSelector(state => state.authState.user);
    const activeProfileChats = useAppSelector(state => state.authState.activeProfileChats);
    const account = useAppSelector(state => state.authState.account);
    const authState = useAppSelector(state => state.authState);

    const checkAddChat =
        authState.activeProfileChats &&
        authState.activeProfileChats[0] &&
        authState.activeProfileChats.reduce((acc: Array<Tchat>, item) => {
            if (item.profile_id == null && item.owner_id == null && item.bot_status == 'connected') {
                acc.push(item);
            }
            return acc;
        }, [])[0];

    return (
        <MobileTable>
            {/* <CFormSelect
                defaultValue={ActiveProfileId}
                onChange={e => {
                    setActiveProfileId(+e.target.value);
                }}
                options={
                    profiles
                        ? [
                              { label: activeLanguage['All.'], value: '0' },
                              ...profiles.profiles.map(el => ({
                                  label: `${el.name}`,
                                  value: `${el.id}`,
                              })),
                          ]
                        : []
                }
            /> */}
            {checkAddChat && <Allert style={{ color: whiteText }} type="addChat" line></Allert>}
            {account &&
                activeProfileChats.map(el => (
                    <MobileTableItem>
                        <OneCell
                            onClick={e => {
                                e.target.classList.toggle('open');
                            }}
                        >
                            <OneCellMain>
                                <div
                                    style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}
                                >
                                    <Switch
                                        initState={el.active}
                                        setState={p => {
                                            setActiveChat({
                                                dispatch,
                                                token,
                                                chat_id: +el.chat_id,
                                                active: p,
                                            }).then(res =>
                                                chats({
                                                    dispatch,
                                                    token,
                                                    profile_id: ActiveProfileId || 0,
                                                }),
                                            );
                                        }}
                                        closed={
                                            el.bot_status == 'insufficient_rights' ||
                                            el.bot_status == 'not_in_chat'
                                                ? true
                                                : el.owner_name != account.username
                                        }    
                                    />
                                    {
                                        activeLanguage[
                                            el.owner_name == account.username
                                                ? 'ChatList.List.State.Active'
                                                : 'ChatList.List.State.NotActive'
                                        ]
                                    }
                                </div>
                            </OneCellMain>
                            {(el.owner_name != account.username ||
                                el.bot_status == 'insufficient_rights') && (
                                <>
                                    <TwoCellIcon className={'info'} src="/img/icon/info.svg" />
                                    <OneCellPopap className={'infoPopap'}>
                                        {el.bot_status == 'insufficient_rights' ||
                                        el.bot_status == 'not_in_chat'
                                            ? activeLanguage['ChatList.PopapLockMine']
                                            : el.owner_id && !el.is_current_user_owner
                                            ? activeLanguage['ChatList.PopapLock']
                                            : !el.owner_id && el.bot_status == 'connected'
                                            ? activeLanguage['ChatList.PopapLockFree']
                                            : ''}
                                    </OneCellPopap>
                                </>
                            )}
                        </OneCell>
                        <TwoCell>
                            <MobileTableItemTitle>
                                {activeLanguage['ChatList.Table.Col2']}:
                            </MobileTableItemTitle>
                            <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
                                {/* <TwoCellIcon src="/img/icon/snowUI_logo.svg" /> */}
                                {el.chat_name}
                            </div>
                        </TwoCell>
                        <ThreeCell className={`${configState[el.bot_status].type}`}>
                            <MobileTableItemTitle>
                                {activeLanguage['ChatList.Table.Col3']}:
                            </MobileTableItemTitle>
                            {activeLanguage[configState[el.bot_status].langText]}
                        </ThreeCell>
                        <FourCell className={`${el.owner_name == account.username ? '' : 'blue'}`}>
                            <MobileTableItemTitle>
                                {activeLanguage['ChatList.Table.Col4']}:
                            </MobileTableItemTitle>
                            {el.owner_name == account.username ? (
                                activeLanguage['ChatList.List.Admin.Mine']
                            ) : el.owner_name ? (
                                <Link to={`${tgUserLinkBase}${el.owner_name}`}>
                                    @{el.owner_name}
                                </Link>
                            ) : (
                                <></>
                            )}
                        </FourCell>
                        <FiveCell>
                            <MobileTableItemTitle>
                                {activeLanguage['ChatList.Table.Col5']}:
                            </MobileTableItemTitle>
                            <CFormSelect
                                value={el.profile_id || 'init'}
                                onChange={e => {
                                    const switchChatBox = document.querySelector(
                                        `[data-switchchatbox="${el.chat_id}"]`,
                                    );
                                    const isActive = switchChatBox?.querySelector('input')?.checked;
                                    if (e.target.value == 'delete') {
                                        deleteProfileChat({
                                            dispatch,
                                            token,
                                            profile_id: +el.profile_id,
                                            chat_id: +el.chat_id,
                                        }).then(res => {
                                            chats({
                                                dispatch,
                                                token,
                                                profile_id: ActiveProfileId,
                                            });
                                        });
                                    } else {
                                        if (el.profile_name) {
                                            deleteProfileChat({
                                                dispatch,
                                                token,
                                                profile_id: +el.profile_id,
                                                chat_id: +el.chat_id,
                                                new_profile_id: +e.target.value,
                                            }).then(res => {
                                                if (res) {
                                                    addProfileChat({
                                                        dispatch,
                                                        token,
                                                        profile_id: res,
                                                        chat_id: +el.chat_id,
                                                        isActive: isActive || false,
                                                    }).then(res => {
                                                        chats({
                                                            dispatch,
                                                            token,
                                                            profile_id: ActiveProfileId,
                                                        });
                                                    });
                                                }
                                            });
                                        } else {
                                            addProfileChat({
                                                dispatch,
                                                token,
                                                profile_id: +e.target.value,
                                                chat_id: +el.chat_id,
                                                isActive: isActive || false,
                                            }).then(res => {
                                                chats({
                                                    dispatch,
                                                    token,
                                                    profile_id: ActiveProfileId,
                                                });
                                            });
                                        }
                                    }
                                }}
                                options={
                                    profiles
                                        ? [
                                            {
                                                label: activeLanguage[''],
                                                value: 'init',
                                                disabled: true,
                                            },
                                            ...profiles.profiles
                                                .map(el => {
                                                    el = { ...el };
                                                    if (el.name == '' || el.name == null) {
                                                        el.name = activeLanguage['ProfileList.Name.Init'];
                                                    }
                                                    return el;
                                                })
                                                .map(el => ({
                                                    label: `${el.name}`,
                                                    value: `${el.id}`,
                                                })),
                                                {
                                                    label: activeLanguage['ChatList.WithoutProfile'],
                                                    value: 'delete',
                                                },
                                          ]
                                        : [el.chat_id]
                                }
                            />
                            <FiveCellIcon
                                to={'/app/profileSettings/' + el.profile_id}
                                onClick={e => {
                                    if (el.profile_id == null) {
                                        e.preventDefault();
                                    }
                                }}
                                className={`icon-arrowCircle ${
                                    el.profile_id == null ? 'gray' : ''
                                }`}
                            />
                        </FiveCell>
                    </MobileTableItem>
                ))}
        </MobileTable>
    );
};
