import React, { useEffect } from 'react';
import style from './style';
import RouteWrapper from '../../middleComponents/routeWrapper';
import Main from './main';
import { useParams } from 'react-router-dom';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import useAppSelector from '@maket/js/hooks/useAppSelector';

export default () => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.tokenState.token);
    const { size } = style();
    const { idProfile } = useParams();

    useEffect(() => {
        getProfile({ token, dispatch });
    }, []);

    return (
        <RouteWrapper
            title={idProfile ? 'ChatRules.Profile.Title' : 'ProfileSettings.Title'}
            background="#00000000"
            mainComponent={<Main />}
            type="allComponents"
            mainContainerProps={{
                style: {
                    padding: size.isMobile
                        ? '1.5em 0.5em'
                        : size.isTab
                        ? '1.75em 2.31em'
                        : '2.75em 2.75em',
                },
            }}
            profileSelect={'profileSettings'}
            tabsActive
        />
    );
};
