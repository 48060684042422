import React, { HTMLAttributes } from 'react';
import style from './style';
import Button from '@maket/js/components/microComponets/button';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { NavLink } from 'react-router-dom';
import { Tchat } from '@maket/js/types/state/auth';

interface Tprops extends HTMLAttributes<HTMLDivElement> {
    type: 'addChat' | 'connectTg' | 'upgrade' | 'chooseTariff';
    line?: boolean;
}

const config = {
    addChat: {
        title: 'Menu.Allert.AddChat.Title',
        text: 'Menu.Allert.AddChat.Text',
        button: 'Menu.Allert.AddChat.Button',
        iconName: 'attention',
        link: 'chatList',
    },
    connectTg: {
        title: 'Menu.Allert.ConnectTg.Title',
        text: 'Menu.Allert.ConnectTg.Text',
        button: 'Menu.Allert.ConnectTg.Button',
        iconName: 'telegram',
        link: 'connectTgAccount',
    },
    upgrade: {
        title: 'Menu.Allert.Upgrade.Title',
        text: 'Menu.Allert.Upgrade.Text',
        button: 'Menu.Allert.Upgrade.Button',
        iconName: '',
        link: 'billing',
    },
    chooseTariff: {
        title: 'Menu.Allert.ChooseTariff.Title',
        text: 'Menu.Allert.ChooseTariff.Text',
        button: 'Menu.Allert.ChooseTariff.Button',
        iconName: '',
        link: 'billing',
    },
};

export default ({ type, line, ...props }: Tprops) => {
    const { Container, IconBox, Icon, IconText, Text } = style(type, line);
    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const authState = useAppSelector(state => state.authState);
    const account = useAppSelector(state => state.authState.account);

    const counterChats = authState.activeProfileChats.reduce((acc: Array<Tchat>, item) => {
        if (item.profile_id == null && item.owner_id == null && item.bot_status == 'connected') {
            acc.push(item);
        }
        return acc;
    }, []);

    return (
        <Container {...props}>
            <IconBox>
                {type != 'upgrade' && <Icon className={`icon-${config[type].iconName}`} />}
                <IconText>{activeLanguage[config[type].title]}</IconText>
            </IconBox>
            <Text>
                {type == 'addChat'
                    ? `${activeLanguage[config[type].text].split('/')[0]}${counterChats.length} ${
                          activeLanguage[config[type].text].split('/')[1]
                      }`
                    : activeLanguage[config[type].text]}
            </Text>
            {!line && (
                <NavLink to={`/app/${config[type].link}`}>
                    <Button style={{ width: '100%', border: 'none' }} typeButt="black">
                        {activeLanguage[config[type].button]}
                    </Button>
                </NavLink>
            )}
        </Container>
    );
};
