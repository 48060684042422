import React from 'react';
import style from './style';
import editProfile from '@maket/js/api/requiresToken/profile/editProfile';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import Input from '@maket/js/components/microComponets/input';
import deleteProfile from '@maket/js/api/requiresToken/profile/deleteProfile';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import { CBadge, CFormSelect } from '@coreui/react';
import deleteLogger from '@maket/js/api/requiresToken/logger/deleteLogger';
import setLogger from '@maket/js/api/requiresToken/logger/setLogger';

type Tprops = {};

export default ({}: Tprops) => {
    const dispatch = useAppDispatch();
    const {
        MobileTable,
        MobileTableItem,
        MobileTableItemTitle,
        OneCell,
        OneCellText,
        OneCellMain,
        OneCellIcon,
        TwoCell,
        ThreeCell,
        FourCell,
        FiveCell,
    } = style();

    const activeLanguage = useAppSelector(state => state.languageState.activeLanguage);
    const language = useAppSelector(state => state.languageState.language);
    const profiles = useAppSelector(state => state.authState.user?.profiles);
    const token = useAppSelector(state => state.tokenState.token);
    const loggerList = useAppSelector(state => state.authState.logerList);

    return (
        <MobileTable>
            {profiles?.map(el => (
                <MobileTableItem to={`/app/chatList/${el.id}`}>
                    <OneCell to={`/app/chatList/${el.id}`}>
                        <MobileTableItemTitle>
                            {activeLanguage['ProfileList.Column.OneName']}:
                        </MobileTableItemTitle>
                        <OneCellMain data-oneCell>
                            <OneCellIcon className={`icon-pencilSimple`} />
                            <OneCellText className="title">{el.name != '' ? el.name : activeLanguage['ProfileList.Name.Init']}</OneCellText>
                        </OneCellMain>
                    </OneCell>
                    <TwoCell
                        onClick={() => {
                            if (!el.logger_channel_name) {
                            }
                        }}
                    >
                        <MobileTableItemTitle style={{ minWidth: '8em' }}>
                            {activeLanguage['ProfileList.Column.TwoName']}:
                        </MobileTableItemTitle>
                        <CFormSelect
                            defaultValue={
                                el.logger_channel_id
                                    ? el.logger_channel_id
                                    : loggerList.length == 0
                                    ? el.logger_channel_id
                                        ? el.logger_channel_id
                                        : ''
                                    : 'add'
                            }
                            aria-label="Default select example"
                            onChange={(e: any) => {
                                if (e.target.value == 'null') {
                                    deleteLogger({ dispatch, token, profile_id: el.id }).then(res =>
                                        getProfile({ token, dispatch }),
                                    );
                                } else {
                                    setLogger({
                                        dispatch,
                                        token,
                                        profile_id: el.id,
                                        logger_channel_id: e.target.value,
                                    }).then(res => getProfile({ token, dispatch }));
                                }
                            }}
                            options={
                                !el.logger_channel_id
                                    ? [
                                        {
                                            label: activeLanguage['Add.'],
                                            value: 'add',
                                            disabled: true,
                                        },
                                        ...loggerList.map(loger => {
                                            return {
                                                label: loger.channel_name,
                                                value: `${loger.channel_id}`,
                                            };
                                        }),
                                    ]
                                    : loggerList.length == 0
                                    ? [{ label: el.logger_channel_name, value: el.logger_channel_id }]
                                    : [
                                        ...loggerList.map(loger => {
                                            return {
                                                label: loger.channel_name,
                                                value: `${loger.channel_id}`,
                                            };
                                        }),
                                        { label: activeLanguage['Delete.'], value: 'null' },
                                    ]
                            }
                            disabled={loggerList.length == 0}
                        />
                    </TwoCell>
                    <ThreeCell>
                        <MobileTableItemTitle>
                            {activeLanguage['ProfileList.Column.ThreeName']}:
                        </MobileTableItemTitle>
                        {el.create_dt.split('T')[0].split('-').join('/')}
                    </ThreeCell>
                    <FourCell>
                        <MobileTableItemTitle>
                            {activeLanguage['ProfileList.Column.FourName']}:
                        </MobileTableItemTitle>
                        {el.chats}
                    </FourCell>
                    <FiveCell>
                        <MobileTableItemTitle>
                            {activeLanguage['ProfileList.Column.FiveName']}:
                        </MobileTableItemTitle>
                        <CBadge color={el.status == 'active' ? 'success' : 'danger'}>
                            {el.status}
                        </CBadge>
                    </FiveCell>
                </MobileTableItem>
            ))}
        </MobileTable>
    );
};
