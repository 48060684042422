import bind_by_code from '@maket/js/api/auth/bind_by_code';
import googleAuth from '@maket/js/api/auth/google/googleAuth';
import loginByCode from '@maket/js/api/auth/loginByCode';
import logout from '@maket/js/api/logout/logout';
import getAccount from '@maket/js/api/requiresToken/account/getAccount';
import chats from '@maket/js/api/requiresToken/chat/chats';
import getProfile from '@maket/js/api/requiresToken/payments/getProfile';
import getTarifState from '@maket/js/api/requiresToken/payments/getTarifState';
import getConnectionList from '@maket/js/api/requiresToken/service/getConnectionList';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import useGetToken from '@maket/js/hooks/useGetToken';
import { researchValue } from '@maket/js/state/authInputsValue/authInputsValueState';
import { langCodes } from '@maket/js/state/language/comparisonBCP47';
import { setLanguage } from '@maket/js/state/language/languageState';
import { TappDispatch } from '@maket/js/state/store';
import tokenState, { setToken } from '@maket/js/state/tokenState';
import { TauthState } from '@maket/js/types/state/auth';
import React, { useEffect } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';

let initRefreshBilling = true;
let initRefreshDashboard = true;
let initRefreshConnectTg = true;

let reloadCheck = false;
let initPathReload = '';

let requestBindByCodeLocker = true;

export function unlockReloadCheck() {
    reloadCheck = false;
}

export default () => {
    const tokenCookie = useGetToken();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { idProfile } = useParams();

    useEffect(() => {
        initPathReload = location.pathname;
    }, []);

    useEffect(() => {
        const navigationEntries = window.performance.getEntriesByType('navigation');
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
            reloadCheck = true;
        } else {
            reloadCheck = false;
        }
    }, []);

    const tokenState = useAppSelector(state => state.tokenState.token);
    const authState = useAppSelector(state => state.authState);

    const tokenCookieCheck = tokenCookie && tokenCookie != 'undefined';

    let locationState = location.state;
    if (!locationState) {
        if (location.hash.includes('assign_tg_code')) {
            // @ts-ignore: Unreachable code error
            const state = createLocationState(location);
            locationState = state;
        }
    }
    if (!locationState || locationState.confirm) {
        let tgAccessState = document.cookie
            .split(';')
            .filter(el => el.includes('tgAccessState'))[0];
        if (tgAccessState) {
            const splitStr = tgAccessState.split('tgAccessState=')[1];
            if (splitStr && splitStr != 'undefined') {
                tgAccessState = JSON.parse(splitStr);
                locationState = tgAccessState;
            }
        }
    }

    useEffect(() => {
        if (authState.account == null) {
            initRefreshBilling = true;
            initRefreshDashboard = true;
            initRefreshConnectTg = true;
        }
    }, [authState.account]);

    useEffect(() => {
        const as = async () => {
            let checkValidToken = false;
            if (tokenState && tokenState.length > 10) {
                await getAccount({ token: tokenState, dispatch, checkToken: true })
                    .then(res => {
                        if (
                            res.response &&
                            (res.response.status == 401 || res.response.status == 403)
                        ) {
                            checkValidToken = false;
                            document.cookie = `token=${undefined}; path=/;`;
                        } else if (res?.error?.includes('Invalid session provided')) {
                            checkValidToken = false;
                            document.cookie = `token=${undefined}; path=/;`;
                        } else {
                            checkValidToken = true;
                        }
                    })
                    .catch(err => {
                        if (err.response && err.response.status == 401) {
                            checkValidToken = false;
                            document.cookie = `token=${undefined}; path=/;`;
                        }
                    });
            }
            if (tokenState && checkValidToken) {
                getProfile({ token: tokenState, dispatch });
                getTarifState({ dispatch, token: tokenState, serviceType: 'telegram' });

                // getConnectService({ token: tokenState, serviceType: 'telegram', dispatch });
                getConnectionList({ token: tokenState, dispatch });
                if (idProfile) {
                    chats({ token: tokenState, dispatch, profile_id: +idProfile });
                } else {
                    chats({ token: tokenState, dispatch, profile_id: 0 });
                }
            }
        };
        as();
    }, [tokenState]);

    useEffect(() => {
        if (authState.user) {
            redirectAuth(
                authState,
                navigate,
                location.pathname,
                locationState,
                tokenCookieCheck,
                dispatch,
                tokenCookie,
            );
        }
    }, [
        authState.user,
        authState.serviceList,
        authState.account,
        authState.service,
        authState.activeProfileChats,
    ]);

    useEffect(() => {
        if (!location.pathname.includes('billing') || authState.tariff.switch_to_tariff == null) {
            redirectAuth(
                authState,
                navigate,
                location.pathname,
                locationState,
                tokenCookieCheck,
                dispatch,
                tokenCookie,
            );
        }
    }, [authState.tariff]);

    useEffect(() => {
        if (['/app/auth/auth', '/app/auth/reg'].includes(location.pathname)) {
            if (authState.user && authState.user.profiles[0]) {
                redirectAuth(
                    authState,
                    navigate,
                    location.pathname,
                    locationState,
                    tokenCookieCheck,
                    dispatch,
                    tokenCookie,
                );
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if (tokenCookieCheck) {
            dispatch(setToken(tokenCookie));
        }
    }, []);
    return <></>;
};

let reqTgCheck = true;
function redirectAuth(
    authState: TauthState,
    navigate: NavigateFunction,
    location: string,
    locationState: { [key: string]: string },
    tokenCookieCheck: boolean | '',
    dispatch: TappDispatch,
    tokenCookie: string,
) {
    if (locationState && locationState.tg_id && !tokenCookieCheck) {
        navigate('/app/auth/auth', { state: locationState });
    }

    if (
        locationState?.confirm &&
        (locationState.confirm == 'confirmChangeEmailErr' ||
            locationState.confirm == 'confirmChangeEmailErrSuccess') &&
        authState.account
    ) {
        navigate('/app/safety', { state: locationState });
    } else if (locationState?.safetyReload) {
        navigate('/app/safety');
    } else if (
        locationState &&
        locationState.tg_id &&
        authState.serviceList[0] &&
        +locationState.tg_id != +authState.serviceList[0].service_user_id
    ) {
        if (locationState.result == 'success') {
            incorrectTgAccountFn(dispatch, navigate);
        } else {
            if (!authState.serviceList[0].service_user_id) {
                if (tokenState) {
                    if (locationState?.result == 'fail') {
                        if (requestBindByCodeLocker) {
                            requestBindByCodeLocker = false;
                            bind_by_code({
                                token: tokenCookie,
                                service_type: 'telegram',
                                tg_id: locationState.tg_id,
                                tg_str_id: locationState.tg_str_id,
                                code: locationState.code,
                            })
                                .then(res => {
                                    requestBindByCodeLocker = true;
                                    getConnectionList({ token: tokenCookie, dispatch });
                                })
                                .catch(err => {
                                    requestBindByCodeLocker = true;
                                });
                        }
                    }
                } else {
                    navigate('/app/connectTgAccount');
                }
            } else {
                incorrectTgAccountFn(dispatch, navigate);
            }
        }
    } else if (authState.account) {
        if (authState.tariff.current_tariff !== undefined) {
            if (authState.tariff.current_tariff && authState.serviceList[0] != undefined) {
                if (
                    authState.serviceList[0].type == 'telegram' &&
                    !!authState.serviceList[0].connected == false &&
                    !location.includes('connectedAccount')
                ) {
                    if (initRefreshConnectTg || locationState) {
                        initRefreshConnectTg = false;
                        initRefreshDashboard = false;
                        if (locationState) {
                            if (tokenState) {
                                if (locationState?.result == 'fail') {
                                    if (requestBindByCodeLocker) {
                                        requestBindByCodeLocker = false;
                                        bind_by_code({
                                            token: tokenCookie,
                                            service_type: 'telegram',
                                            tg_id: locationState.tg_id,
                                            tg_str_id: locationState.tg_str_id,
                                            code: locationState.code,
                                        })
                                            .then(res => {
                                                requestBindByCodeLocker = true;
                                                getConnectionList({ token: tokenCookie, dispatch });
                                            })
                                            .catch(err => {
                                                requestBindByCodeLocker = true;
                                            });
                                    }
                                }
                            }
                        } else {
                            navigate('/app/connectTgAccount');
                        }
                    }
                }
            }

            if (
                authState.tariff.current_tariff &&
                ([
                    '/app/auth/auth',
                    '/app/auth/reg',
                    '/app/connectTgAccount',
                    '/app/billing',
                ].includes(location) ||
                    location == '/')
            ) {
                if (
                    authState.serviceList[0] != undefined &&
                    authState.serviceList[0].type == 'telegram' &&
                    !!authState.serviceList[0].connected == true
                ) {
                    const userChat = authState.activeProfileChats.find(
                        el => el.owner_name == authState.account?.username,
                    );

                    if (authState.activeProfileChats.length == 0 || !userChat) {
                        navigate('/app/chatList');
                    } else {
                        if (initRefreshDashboard) {
                            if (authState.activeProfileChats.length != 0) {
                                initRefreshDashboard = false;
                            }

                            // if (!reloadCheck) {
                            //     navigate('/app/dashboard');
                            // } else {
                            //     navigate(initPathReload);
                            // }
                            navigate('/app/dashboard');
                        }
                    }

                    document.cookie = `tgAccessState=${undefined}; path=/;`;
                } else {
                    if (locationState && locationState.result) {
                        if (tokenCookie && tokenCookie != 'undefined') {
                            if (locationState?.result == 'fail') {
                                if (requestBindByCodeLocker) {
                                    requestBindByCodeLocker = false;
                                    bind_by_code({
                                        token: tokenCookie,
                                        service_type: 'telegram',
                                        tg_id: locationState.tg_id,
                                        tg_str_id: locationState.tg_str_id,
                                        code: locationState.code,
                                    })
                                        .then(res => {
                                            requestBindByCodeLocker = true;
                                            getConnectionList({ token: tokenCookie, dispatch });
                                        })
                                        .catch(err => {
                                            requestBindByCodeLocker = true;
                                        });
                                }
                            }
                        }
                    } else {
                        navigate('/app/connectTgAccount');
                    }
                }
            }
            if (!authState.tariff.current_tariff && location != '/app/myData') {
                if (
                    initRefreshBilling ||
                    (location == '/app/connectTgAccount' &&
                        authState.serviceList[0].type &&
                        !!authState.serviceList[0].connected == true)
                ) {
                    initRefreshBilling = false;
                    navigate('/app/billing');
                    if (
                        authState &&
                        authState.serviceList[0] &&
                        authState.serviceList[0].type &&
                        !!authState.serviceList[0].connected == true
                    ) {
                        document.cookie = `tgAccessState=${undefined}; path=/;`;
                    }
                }
            }
        }
    } else {
        if (locationState && locationState.result == 'success') {
            if (reqTgCheck) {
                reqTgCheck = false;
                loginByCode({ dispatch, code: locationState.code })
                    .then(res => {
                        reqTgCheck = true;
                    })
                    .catch(err => {
                        reqTgCheck = true;
                    });
            }
        } else if (locationState && locationState.lang) {
            let lang = locationState.lang.toUpperCase();
            if (!langCodes.includes(lang)) {
                lang = 'EN';
            }
            dispatch(setLanguage(lang));
        }
    }
}

export function createLocationState(location: Location) {
    const state = {
        tg_id: location.hash.split('tg_id=')[1].split('&')[0],
        tg_str_id: location.hash.split('tg_str_id=')[1].split('&')[0],
        code: location.hash.includes('fail')
            ? location.hash.split('assign_tg_code=')[1].split('&')[0]
            : location.hash.split('code=')[1].split('&')[0],
        result: location.hash.split('result=')[1].split('&')[0],
        lang: location.hash.split('lang=')[1].split('&')[0],
    };
    document.cookie = `tgAccessState=${JSON.stringify(state)}; path=/;`;
    return state;
}

function incorrectTgAccountFn(dispatch: TappDispatch, navigate: NavigateFunction) {
    logout(dispatch, navigate, true);
    setTimeout(() => {
        navigate('/app/auth/auth', {
            state: { userTgError: 'incorrectTgAccount' },
        });
        window.location.reload();

        // dispatch(researchValue('refresh'));
        // setTimeout(() => {
        //     navigate('/app/auth/auth', {
        //         flushSync: true,
        //         state: { userTgError: 'incorrectTgAccount' },
        //     });
        //     dispatch(researchValue(''));
        // }, 10000);
    }, 500);
}
