import React from 'react';
import style from './style';
import { AppSidebarNav } from './item';
import useWindowSizeCheck from '@maket/js/hooks/useWindowSizeCheck';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import Bottom from './bottom';
import navigation from './navigation';
import Allert from './allert';
import { Tchat } from '@maket/js/types/state/auth';
import { useLocation, useParams } from 'react-router-dom';

export default () => {
    const location = useLocation();

    const { Container } = style();
    const { isMobile, isTab } = useWindowSizeCheck();
    const activePopapName = useAppSelector(state => state.openPopapState.activePopapName);
    const user = useAppSelector(state => state.authState.user);

    const authState = useAppSelector(state => state.authState);
    const unfoldable = useAppSelector(state => state.headerState.unfoldable);

    const connectTgCheck =
        authState.account &&
        authState.tariff.current_tariff != undefined &&
        authState.tariff.current_tariff &&
        authState.serviceList[0] != undefined &&
        authState.serviceList[0].type == 'telegram' &&
        !!authState.serviceList[0].connected == false;

    const upgradeCheck =
        authState.account &&
        authState.tariff &&
        authState.tariff.current_tariff &&
        authState.tariff.current_tariff?.tariff.key != 'expert';

    const chooseTariffCheck =
        authState.account &&
        !authState?.tariff?.current_tariff;

    const checkAddChat =
        authState.activeProfileChats &&
        authState.activeProfileChats[0] &&
        authState.activeProfileChats.reduce((acc: Array<Tchat>, item) => {
            if (item.profile_id == null && item.owner_id == null && item.bot_status == 'connected') {
                acc.push(item);
            }
            return acc;
        }, [])[0];

    // const { idProfile } = useParams();

    // const checkProfileList =
    //     idProfile != undefined &&
    //     (location.pathname.includes('profileSettings') ||
    //         location.pathname.includes('chatRules') ||
    //         location.pathname.includes('chatList'));

    return (
        <Container className="scrollbar">
            <AppSidebarNav
                items={navigation(
                    user != null ? 'auth' : 'noAuth',
                    location.pathname.includes('documentation') ? 'doc' : 'init',
                )}
            />

            {(isMobile || isTab) && (
                <Bottom
                    initState={
                        activePopapName == 'navigationPopapAndLanguage' ? 'languagePopap' : ''
                    }
                />
            )}

            {!unfoldable && checkAddChat && <Allert type="addChat" />}
            {!unfoldable && connectTgCheck && <Allert type="connectTg" />}
            {!unfoldable && upgradeCheck && <Allert type="upgrade" />}
            {!unfoldable && chooseTariffCheck && <Allert type="chooseTariff" />}
        </Container>
    );
};
