import { apiUrl } from '@js/constants/values';
import { setVisableLoader } from '@maket/js/components/mainComponents/loaderTopRight';
import { setAuth } from '@maket/js/state/auth/authState';
import { TappDispatch } from '@maket/js/state/store';
import { Tuser } from '@maket/js/types/state/auth';
import axios from 'axios';

type Tprops = {
    token: string;
    dispatch: TappDispatch;
};

export default async ({ token, dispatch }: Tprops) => {
    if (token == '') {
        return
    }

    try {
        setVisableLoader('visable');
        const req = await axios.get<Tuser>(apiUrl + '/profiles', {
            headers: { Authorization: `Bearer ${token}` },
        });
        setVisableLoader('notVisable');
        dispatch(setAuth(req.data));
    } catch (e) {
        setVisableLoader('notVisable');
    }
};
