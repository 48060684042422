import React, { useCallback, useEffect, useState } from 'react';
import style from './style';
import Button from '@js/components/microComponets/button';
import { NavLink } from 'react-router-dom';
import useAppDispatch from '@maket/js/hooks/useAppDispatch';
import {
    setActiveKey,
    setPopapStateEnterprice,
    setPopapStateSub,
} from '@maket/js/state/tariffSwith';
import useAppSelector from '@maket/js/hooks/useAppSelector';
import { clickButton } from '../localPopapElSub';
import { Taccount, TtariffElement } from '@maket/js/types/state/auth';
import { TappDispatch } from '@maket/js/state/store';
import ButtonAllert, { TalertState } from '@maket/js/components/microComponets/buttonAllert';

type Tprops = {
    connect: string;
    checkActive: boolean;
    cardKey: string;
    connectType: 'click' | 'noClick';

    stopDateActiveTariff: string;
    nextPriceActiveTariff: number;
    nextPriceSwitchTariff: number;

    tariffSwitch: TtariffElement;

    prolongation: boolean | undefined;
    stopDate: string | null | undefined;
    type?: 'page';
};

export default ({
    type,
    connect,
    checkActive,
    cardKey,
    connectType,
    nextPriceActiveTariff,
    nextPriceSwitchTariff,
    stopDateActiveTariff,
    prolongation,
    tariffSwitch,
    stopDate,
}: Tprops) => {
    const dispatch = useAppDispatch();
    const { ActiveButton, ActiveButtonText, size } = style();
    const { activeLanguage, activePriceSymbolType } = useAppSelector(state => state.languageState);

    const accountState = useAppSelector(state => state.authState.account);
    const token = useAppSelector(state => state.tokenState.token);
    const tariffState = useAppSelector(state => state.tariffState.state);

    const [allertState, setAllertState] = useState<TalertState>({ textAllert: '', typeAllert: '' });

    const callbackSetAllertState = useCallback((v: TalertState) => {
        setAllertState(v);
    }, []);

    useEffect(() => {
        allertState;
    });

    if (checkActive || tariffSwitch?.tariff.key == cardKey) {
        return (
            <ActiveButton
                style={{
                    height: size.isMobile ? '3.5em' : size.isTab ? '4.5em' : '4em',
                    maxHeight: size.isMobile ? '3.5em' : size.isTab ? '4.5em' : '4em',
                }}
            >
                <ActiveButtonText className="blue">
                    {
                        activeLanguage[
                            stopDate == null && tariffSwitch?.tariff.key != cardKey
                                ? 'Main.Price.ActiveIndefinite'
                                : tariffSwitch?.tariff.key == cardKey
                                ? 'Main.Price.ActiveAfter'
                                : 'Main.Price.Active'
                        ]
                    }
                </ActiveButtonText>{' '}
                <ActiveButtonText className="blue">
                    {tariffSwitch?.tariff.key == cardKey
                        ? tariffSwitch.start_date + ' '
                        : tariffSwitch?.start_date == new Date().toISOString().split('T')[0]
                        ? tariffSwitch?.start_date
                        : tariffSwitch
                        ? activeLanguage['Main.Price.ActiveOn'] + ' ' + stopDateActiveTariff
                        : !prolongation
                        ? activeLanguage['Main.Price.ActiveOn'] + ' ' + stopDateActiveTariff
                        : stopDateActiveTariff + ' '}
                    {prolongation
                        ? tariffSwitch
                            ? tariffSwitch.tariff.key == cardKey
                                ? ''
                                : ''
                            : stopDate != null &&
                              activeLanguage['Main.Price.DownsСompress'] +
                                  ' ' +
                                  `${
                                      activePriceSymbolType == 'dollar'
                                          ? activeLanguage['SymbolDollar.']
                                          : ''
                                  }${nextPriceActiveTariff}${
                                      activePriceSymbolType == 'euro'
                                          ? activeLanguage['SymbolEuro.']
                                          : ''
                                  }`
                        : ''}
                </ActiveButtonText>
            </ActiveButton>
        );
    }
    if (type == 'page') {
        return (
            <ButtonAllert
                buttonClassNames="button"
                buttonStyle={{
                    width: '100%',
                    height: size.isMobile ? '3.5em' : size.isTab ? '4.5em' : '4em',
                    maxHeight: size.isMobile ? '3.5em' : size.isTab ? '4.5em' : '4em',
                    fontWeight: 400,
                    padding: '0.637em',
                    whiteSpace: 'wrap',
                }}
                typeButt={
                    connectType == 'click' || tariffSwitch?.tariff.key == cardKey ? 'black' : 'gray'
                }
                onClick={() => {
                    if (cardKey == 'enterprise') {
                        dispatch(setPopapStateEnterprice(true));
                    } else {
                        clickButtonLinter(
                            connectType,
                            tariffSwitch,
                            accountState,
                            dispatch,
                            token,
                            cardKey,
                            tariffState,
                            callbackSetAllertState,
                        );
                    }
                }}
                buttonContent={connect}
                stateAllertOuter={allertState}
                setAllertFn={setAllertState}
                timer={3000}
            />
        );
    } else {
        return (
            <NavLink
                onClick={e => {
                    if (cardKey == 'enterprise') {
                        e.preventDefault();
                        dispatch(setPopapStateEnterprice(true));
                    } else {
                        localStorage.setItem(
                            'MZPreRegTariff',
                            JSON.stringify({ tariffKey: cardKey, timestamp: +new Date() }),
                        );
                    }
                }}
                to={accountState?.id ? '/app/billing' : '/app/auth/auth'}
            >
                <Button
                    className="button"
                    style={{
                        width: '100%',
                        height: size.isMobile
                            ? 'calc(1em * 2.5 + 1em)'
                            : size.isTab
                            ? 'calc(1em * 3.5 + 1em)'
                            : 'calc(1em * 3 + 1em)',
                        maxHeight: size.isMobile
                            ? 'calc(1em * 2.5 + 1em)'
                            : size.isTab
                            ? 'calc(1em * 3.5 + 1em)'
                            : 'calc(1em * 3 + 1em)',
                        fontWeight: 400,
                        padding: '0.637em',
                        whiteSpace: 'wrap',
                    }}
                    typeButt="black"
                >
                    {connect}
                </Button>
            </NavLink>
        );
    }
};

function clickButtonLinter(
    connectType: 'click' | 'noClick',
    tariffSwitch: TtariffElement,
    accountState: Taccount,
    dispatch: TappDispatch,
    token: string,
    cardKey: string,
    tariffState: boolean,
    callbackSetAllertState: (v: TalertState) => void,
) {
    if (connectType == 'click') {
        if (tariffSwitch == null) {
            if (accountState?.agreement_tos_datetime) {
                clickButton(dispatch, token, cardKey, tariffState, callbackSetAllertState);
            } else {
                dispatch(setActiveKey(cardKey));
                dispatch(setPopapStateSub(true));
            }
        }
    }
}
