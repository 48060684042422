import { TsettingProfileValueState } from '@maket/js/types/state/settingProfile';
import { createSlice } from '@reduxjs/toolkit';

const initialState: TsettingProfileValueState = {
    name: '',
    active: null,
    moder: null,
    protection: null,
    lang: null,
    delete_forwarded_story: null,
    delete_anonymous_messages: null,
};

const settingProfileValueState = createSlice({
    name: 'settingProfileValueState',
    initialState,
    reducers: {
        setInputNameSettingsValue: (state, { payload }: { payload: string }) => {
            state.name = payload;
        },
        setActiveProfile: (state, { payload }: { payload: boolean }) => {
            console.log(payload);
            if (payload != state.active) {
                state.active = payload;
            }
            console.log(state.active);
        },
        setModerProfile: (state, { payload }: { payload: boolean }) => {
            if (payload != state.moder) {
                state.moder = payload;
            }
        },
        setProtectionProfile: (state, { payload }: { payload: boolean }) => {
            if (payload != state.protection) {
                state.protection = payload;
            }
        },
        setLangProfile: (state, { payload }: { payload: string }) => {
            if (payload != state.lang) {
                state.lang = payload;
            }
        },
        setDeleteForwardedStoryProfile: (state, { payload }: { payload: boolean }) => {
            if (payload != state.delete_forwarded_story) {
                state.delete_forwarded_story = payload;
            }
        },
        setDeleteAnonymousMessagesProfile: (state, { payload }: { payload: boolean }) => {
            if (payload != state.delete_anonymous_messages) {
                state.delete_anonymous_messages = payload;
            }
        },
        setHistoryProfile: (state, { payload }: { payload: TsettingProfileValueState }) => {
            state = payload;
        },

        clearProfileSettings: state => {
            state.name = '';
            state.active = null;
            state.moder = null;
            state.protection = null;
            state.lang = null;
            state.delete_forwarded_story = null;
            state.delete_anonymous_messages = null;
        },
    },
});

export const {
    setInputNameSettingsValue,
    setActiveProfile,
    setModerProfile,
    setProtectionProfile,
    setLangProfile,
    setDeleteForwardedStoryProfile,
    setDeleteAnonymousMessagesProfile,
    setHistoryProfile,
    clearProfileSettings,
} = settingProfileValueState.actions;

export default settingProfileValueState;
